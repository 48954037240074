<template>
  <main>
    <div class="page-header">
      <div class="container-fluid">
        <breadcrumbs></breadcrumbs>
        <div class="d-md-flex align-items-end justify-content-between">
          <h1 class="page-title mb-3 mb-md-2">Web Filing Dashboard</h1>
          <a class="btn btn-lg btn-info mb-2" href="#"><svg class="icon icon-add"><use xlink:href="/icons/symbol-defs.svg#icon-add"></use></svg> Create a New Package</a>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">

          <div class="card mt-4">
            <div class="card-header bg-light">
              <div class="d-lg-flex justify-content-between">
                <h3 class="mb-1 mb-lg-0">My Recent Packages</h3>
                <a href="">View All Packages</a>
              </div>
            </div>

            <table class="table table-lg mb-0 border-bottom">
              <tbody>
                <tr>
                  <td><strong><a href="javascript:void(0)">File Reference 1</a></strong></td>
                  <td class="text-right">Status</td>
                </tr>
                <tr>
                  <td><strong><a href="javascript:void(0)">File Reference 2</a></strong></td>
                  <td class="text-right">Status</td>
                </tr>
                <tr>
                  <td><strong><a href="javascript:void(0)">File Reference 2</a></strong></td>
                  <td class="text-right">Status</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
        <div class="col-md-6">

          <div class="card mt-4">
            <div class="card-header bg-light">
              <h3 class="mb-0">My Recent Notifications</h3>
            </div>

            <table class="table table-lg mb-0 border-bottom">
              <tbody>
                <tr>
                  <td><strong><a href="javascript:void(0)">Notification 1</a></strong></td>
                  <td><a href="javascript:void(0)">Package File Reference</a></td>
                  <td class="text-right">Sep 18 2018</td>
                </tr>
                <tr>
                  <td><strong><a href="javascript:void(0)">Notification 2</a></strong></td>
                  <td><a href="javascript:void(0)">Package File Reference</a></td>
                  <td class="text-right">Aug 17 2018</td>
                </tr>
                <tr>
                  <td><strong><a href="javascript:void(0)">Notification 3</a></strong></td>
                  <td><a href="javascript:void(0)">Package File Reference</a></td>
                  <td class="text-right">Aug 09 2018</td>
                </tr>
                <tr>
                  <td><strong><a href="javascript:void(0)">Notification 4</a></strong></td>
                  <td><a href="javascript:void(0)">Package File Reference</a></td>
                  <td class="text-right">Aug 02 2018</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <help-panel title="Create Package"></help-panel>
  </main>
</template>

<script>

import HelpPanel from '../../components/HelpPanel.vue'

export default {

  components: {
    'help-panel': HelpPanel
  },

  data () {
    return {

    }
  }
}
</script>

